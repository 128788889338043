<template lang="pug">
.modal.fade(tabindex="-1" data-backdrop="static")
  .modal-dialog
    .modal-content.media-manager.mini
      form(v-on:submit.prevent="submit")
        .modal-header
          button.close(type="button" @click="show = false")
            i.fa.fa-times
          h3 Search Media
        .modal-body
          div.flex-between
            div.btn-group.btn-group-lg
              button.btn.btn-default(type="button" v-on:click="toggleFilters('images')" v-bind:class="{active: filters.images}")
                i.fa.fa-image
              button.btn.btn-default(type="button" v-on:click="toggleFilters('videos')" v-bind:class="{active: filters.videos}")
                i.fa.fa-video-camera
              button.btn.btn-default(type="button" v-on:click="toggleFilters('documents')" v-bind:class="{active: filters.documents}")
                i.fa.fa-file-text-o
            form.form-inline(v-on:submit.prevent="loadMedia({})")
              .input-group.input-group-lg
                input.form-control(type="search" placeholder="Search" v-model="search")
                span.input-group-btn
                  button.btn.btn-default(type="submit")
                    i.fa.fa-search

          br

          p.text-center Select a file from the media library
          hr

          LoaderOverlay(:loading="loading")
            .row
              .file-grid(v-for="medium in searchResults" v-on:click="TOGGLE(medium.id)")
                Medium(:medium="medium" :selected="isSelected(medium.id)")

              .col-sm-12.text-center(v-if="searchResults.length == 0")
                .alert.alert-info No results were found

            .row
              .col-md-12.text-center
                Pagination(
                  :current="page.current"
                  :per="perPage"
                  :total-records="page.total_records"
                  :max-pages="5"
                  @change="changePage"
                )

        .modal-footer
          button.btn.btn-link.text-muted(@click="show = false" type="button")
            | Cancel
          button.btn.btn-primary(type="submit" :disabled="submitDisabled")
            i.fa.fa-plus
            |
            | Attach
</template>

<script>
  import { mapMutations } from 'vuex'
  import { sync } from 'vuex-pathify'
  import { isEmpty, includes } from 'lodash'
  import pLimit from 'p-limit'

  import LoaderOverlay from '../components/shared/loader_overlay.vue'
  import Medium from './media/medium.vue'
  import Pagination from '../components/shared/pagination.vue'

  export default {
    components: {
      LoaderOverlay,
      Medium,
      Pagination
    },

    props: {
      perPage: {
        type: Number,
        default() { return 6 }
      }
    },

    data() {
      return {
        search: '',
        loading: true,
        filters: {
          images: true,
          videos: false,
          documents: false
        }
      };
    },

    computed: {
      show: sync('mediaSelecting/show'),
      mediumIds: sync('mediaSelecting/mediumIds'),
      searchResults: sync('mediaSelecting/searchResults'),
      page: sync('mediaSelecting/page'),

      submitDisabled() {
        return isEmpty(this.mediumIds)
      },

      filteredTypes() {
        return Object.keys(this.filters).filter(type => this.filters[type])
      }
    },

    watch: {
      show(val) {
        if(val) {
          this.loadMedia({})
        $(this.$el).modal('show')
        } else {
          this.reset()
          $(this.$el).modal('hide')
        }
      },
    },

    methods: {
      ...mapMutations('mediaSelecting', ['TOGGLE']),

      isSelected(mediumId) {
        return includes(this.mediumIds, mediumId)
      },

      loadMedia({ page }) {
        const { search, perPage } = this

        this.loading = true

        this.$store.dispatch(
          'mediaSelecting/search',
          {
            search, perPage,
            filterTypes: this.filteredTypes,
            page: page || 1,
          }
        ).then(() => {
          this.loading = false
        })
      },

      changePage(page) {
        this.loadMedia({ page })
      },

      reset() {
        this.$store.commit('mediaSelecting/RESET')
        this.search = ''
        this.loading = false
      },

      submit() {
        const limit = pLimit(1)

        this.loading = true

        Promise.all(
          this.mediumIds.map(mediumId =>
            limit(() => this.$store.dispatch('storyMedia/createFromMedium', { mediumId }))
          )
        ).then(() => {
          this.mediumIds = []
          this.loading = false
          this.show = false
        }).catch(() => {
          this.loading = false
        })
      },

      toggleFilters(type) {
        this.filters[type] = !this.filters[type]

        this.loadMedia(this.page)
      },
    }
  }
</script>

<style scoped>
.flex-between {
  display: flex;
  justify-content: space-between;
}
</style>
