<template lang="pug">
.story-medium
  .media
    .media-left
      a.image(:href="url" target="_blank" data-toggle="tooltip" :title="$options.filters.mediaFileName(filename)")
        img(v-if="fileType == 'image'" :src="small_thumb_url" width="100")
        i.fa.fa-file(v-if="fileType != 'image'")

    .media-body
      Label(:text="credit" v-if="credit")
      div(v-html="cutline")
      a.media-filename.text-muted(:href="url" v-text="$options.filters.mediaFileName(filename)" v-if="filename")

      .text-right
        LabelledCheckbox(label="Print" :value="for_print")
        LabelledCheckbox(label="Web" :value="for_web")
</template>

<script>
  import { sync } from 'vuex-pathify'
  import LabelledCheckbox from '../../../shared/labelled_checkbox.vue'
  import Label from '../../../shared/label.vue'

  export default {
    components: {
      LabelledCheckbox,
      Label
    },

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        // This is a weird hack to make vue-pathify work with variable expansion.
        // See https://github.com/davestewart/vuex-pathify/issues/103.
        storeRootAttr: 'storyMedia'
      }
    },

    computed: {
      ...sync('storyMedia/:storeRootAttr@:id', [
        'use_as_content_for',
        'for_print',
        'for_web'
      ]),
      ...sync('storyMedia/:storeRootAttr@:id.medium', [
        'cutline',
        'credit',
        'url',
        'small_thumb_url',
        'file',
        'filename',
        'content_type'
      ]),

      fileType() {
        if(this.content_type != null) {
          return this.content_type.split('/')[0]
        }
      },
    }
  }
</script>

<style lang="scss" scoped>
  .story-medium {
    margin-bottom: 20px;

    .media-filename {
      max-width: 250px;
      overflow: hidden;
    }
  }

  .media-left {
    a.image {
      position: relative;
      display: block;
    }
  }
</style>
