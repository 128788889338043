<template lang="pug">
form.nrq-media-filter-bar.form-inline(v-on:submit.prevent="submit")
  input.form-control(type="search" placeholder="Search" v-model="search")
  |
  |
  div.btn-group
    button.btn.btn-default(type="button" v-on:click="filterImages = !filterImages" v-bind:class="{active: filterImages}")
      i.fa.fa-image
    button.btn.btn-default(type="button" v-on:click="filterVideos = !filterVideos" v-bind:class="{active: filterVideos}")
      i.fa.fa-video-camera
    button.btn.btn-default(type="button" v-on:click="filterDocuments = !filterDocuments" v-bind:class="{active: filterDocuments}")
      i.fa.fa-file-text-o
  |
  |
  SqueueCheckboxes(v-model="squeueIds")
  |
  |
  SectionCheckboxes(v-model="sectionIds")
  |
  |
  DateField(:disabled-dates="{from: to}" placeholder="From" v-model="from")
  |
  |
  DateField(:disabled-dates="{to: from}" placeholder="To" v-model="to")
  |
  |
  button.btn.btn-default(type="submit")
    i.fa.fa-filter
    | Filter
</template>

<script>
  import { sync } from 'vuex-pathify'

  import DateField from '../../../components/shared/date_field.vue'
  import SectionCheckboxes from '../../../components/shared/section_checkboxes.vue'
  import SqueueCheckboxes from '../../../components/shared/squeue_checkboxes.vue'

  export default {
    components: {
      DateField,
      SectionCheckboxes,
      SqueueCheckboxes
    },

    computed: {
      ...sync('mediaSearch/search@*')
    },

    methods: {
      submit() {
        this.$store.dispatch('media/search', { page: 1 })
      }
    }
  }
</script>

<style lang="scss">
  .nrq-media-filter-bar {
    .input-group {
      .form-control {
        float: none;
      }
    }
  }
</style>
