<template lang="pug">
.story-medium
  .media
    .media-left
      .image(ref="thumbnail" :title="$options.filters.mediaFileName(filename)" data-toggle="tooltip" data-placement="top")
        i.fa.fa-sort.handle.show-on-hover
        img(v-if="fileType == 'image'" :src="small_thumb_url" width="100")
        i.fa.fa-file(v-if="fileType != 'image'")

    .media-body
      .input-group
        Input.media-heading(
          type="text"
          v-model="credit"
          placeholder="Credit"
        )
        .btn.btn-danger.input-group-addon(type="button" v-on:click="deleteStoryMedia")
          i.fa.fa-trash
      RichtextEditor(
        v-model="cutline"
        placeholder="Cutline"
        classes="form-control-sm"
      )

      div
        .text-right
          LabelledCheckbox.form-row-for-print(label="For print" v-model="for_print")
          LabelledCheckbox.form-row-for-web(label="For web" v-model="for_web")
</template>

<script>
  import { sync } from 'vuex-pathify'
  import Input from '../../../shared/input.vue'
  import TextareaInput from '../../../shared/textarea_input.vue'
  import LabelledCheckbox from '../../../shared/labelled_checkbox.vue'
  import RichtextEditor from '../../../../components/shared/richtext_editor'

  export default {
    components: {
      Input,
      TextareaInput,
      LabelledCheckbox,
      RichtextEditor
    },

    props: {
      id: {
        type: Number,
        required: true
      }
    },

    data() {
      return {
        // This is a weird hack to make vue-pathify work with variable expansion.
        // See https://github.com/davestewart/vuex-pathify/issues/103.
        storeRootAttr: 'storyMedia'
      }
    },

    computed: {
      ...sync('storyMedia/:storeRootAttr@:id', [
        'for_print',
        'for_web',
        'list_order'
      ]),
      ...sync('storyMedia/:storeRootAttr@:id.medium', [
        'cutline',
        'credit',
        'small_thumb_url',
        'filename',
        'content_type'
      ]),

      fileExtension() {
        let name = this.filename

        if (name != null) {
          return name.substring(name.lastIndexOf('.') + 1).toLowerCase()
        }
      },

      fileType() {
        if(this.content_type != null) {
          return this.content_type.split('/')[0]
        }
      }
    },

    mounted() {
      if(this.$refs.thumbnail) {
        jQuery(this.$refs.thumbnail).tooltip()
      }
    },

    methods: {
      deleteStoryMedia() {
        if(!confirm(`Sure you want to delete ${this.filename}`)) return

        this.$store.dispatch('storyMedia/destroy', { storyMediumId: this.id })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .story-medium {
    margin-bottom: 20px;
  }

  .media-body {
    .input-group {
      margin-bottom: 5px;
    }

    .form-row-for-print, .form-row-for-web {
      margin-left: 10px;
    }
  }

  .btn-danger.input-group-addon {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
  }

  .btn-danger.input-group-addon:hover {
    background-color: #c9302c;
    border-color: #ac2925;
  }

  .media-left {
    .image {
      position: relative;
      display: block;

      .handle {
        position: absolute;
        display: block;
        text-align: right;
        right: 5px;
        bottom: 5px;
        height: 1em;
        width: 1em;
        font-size: 3em;
        color: white;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
        cursor: grab;
      }

      .show-on-hover {
        visibility: hidden;
      }

      &:hover .show-on-hover {
        visibility: visible;
      }
    }
  }
</style>
