<template lang="pug">
.story-publication(:class="(otherPublications) ? 'panel panel-default' : ''")
  template(v-if="otherPublications")
    .panel-heading
      h4
        | {{ name }}
        |
        |
        i.fa.fa-angle-down
  div(:class="(otherPublications) ? 'panel-body' : ''")
    LabelledValue(label="Exported at" :value="exportedAt")
    LabelledValue(label="Content type" :value="storyPublication.content_type_key")
    LabelledValue(label="Story template" :value="storyTemplate")
    LabelledValue(label="Subsites" :value="subsiteNames")
</template>

<script>
  import { mapGetters } from 'vuex'
  import { invert, pick } from 'lodash'

  import LabelledValue from '../../../components/shared/labelled_value.vue'

  export default {
    components: {
      LabelledValue
    },

    props: {
      storyPublication: {
        type: Object,
        required: true
      }
    },

    computed: {
      ...mapGetters('publications', { publication: 'forStoryPublication' }),
      ...mapGetters('communityqStoryTemplates', { storyTemplateById: 'byId' }),
      ...mapGetters('subsites', { subsitesByPub: 'forPublicationAsObject' }),

      name() {
        return this.publication(this.storyPublication)?.name
      },

      exportedAt() {
        const { exported_at } = this.storyPublication

        if(!exported_at) return null

        const time = new Date(Date.parse(exported_at))
        return time.toLocaleString()
      },

      otherPublications() {
        return this.$store.getters['publications/publications'].length > 1;
      },

      storyTemplate() {
        const { story_template_id } = this.storyPublication

        return this.storyTemplateById(story_template_id)?.title
      },

      subsiteNames() {
        const { publication_id, subsite_town_ids } = this.storyPublication
        const subsites = this.subsitesByPub(publication_id)

        return Object.values(pick(invert(subsites), subsite_town_ids))
      }
    },

    async created() {
      const { publication_id } = this.storyPublication

      await this.$store.dispatch('communityqStoryTemplates/fetchOnce', publication_id)
    }
  }
</script>
